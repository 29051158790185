import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePageView from "@/views/homeView/HomePageView";
import AboutPage from "@/components/about/AboutPage";
import ContactPage from "@/components/contact/ContactPage";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePageView
  },
  {
    path: '/about',
    name: 'home',
    component: AboutPage
  },
  {
    path: '/contact',
    name: 'home',
    component: ContactPage
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

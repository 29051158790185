<template>
  <div class="tw-mt-5">
    <div class="md:tw-px-10 tw-px-4">
      <div class="about__background tw-w-full tw-mx-auto ">
        <div class="overlay">
          <div style="z-index: 20" class="tw-flex tw-items-center tw-h-full tw-justify-center inv__name">About Us</div>

        </div>

      </div>

    </div>
    <div class="inv__name__second tw-flex tw-items-center tw-h-full tw-justify-center inv__name md:tw-mt-20 tw-mt-10 tw-px-4">
      Denis Silva Limited
    </div>
    <div class="tw-w-7/12 tw-mx-auto tw-block md:tw-hidden">
      <v-btn
          v-scroll="onScroll"
          v-show="fab"
          class="md:tw-mr-16 tw-mr-4 tw-mb-5"
          fab
          dark
          fixed
          bottom
          height="45"
          width="45"
          right
          color="#CEA861"
          @click="toTop"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </div>

    <div class="inv__sub md:tw-w-10/12 tw-w-11/12 tw-mx-auto">
      Denis Silva Textiles is your gateway to a world where textile innovation and timeless elegance combine to redefine your design projects. Our mission is to reveal the distinctive essence of each fabric, whether it’s for a luxurious residence, a professional workspace, or a high-end hospitality venue. At Denis Silva Textiles, we are committed to excellence, craftsmanship, and client satisfaction, ensuring that every textile we create seamlessly marries beauty with practicality. Our offerings include custom fabric development, an extensive range of textures, patterns, and colors, all crafted with meticulous attention to detail.

      What truly sets us apart is our dedication to sustainability. At Denis Silva Textiles, we integrate eco-friendly practices into every step of our process, creating fabrics that are as environmentally responsible as they are beautiful. Our focus is on offering textiles that not only elevate your designs but also reflect our commitment to a greener future.

      Experience the transformative power of fabric with Denis Silva Textiles today. Discover a world where your design vision meets our expertise, where every textile is crafted to endure and inspire, and where innovation and sustainability come together to shape the future of interior design.
    </div>
    <div class="tw-w-11/12 mx-auto tw-mt-10 tw-mb-8">
      <v-footer height="55" style="border-radius: 2px">
        <div class="footer_text md:tw-ml-20">All rights reserved © denis silva limited 2024</div>
      </v-footer>

    </div>

  </div>
</template>

<script>
export default {
  name: "AboutPage",
  data(){
    return {
      fab: false,
    }
  },

  methods:{
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  }
}
</script>

<style scoped>
.about__background{
  background-image: url("../../assets/images/about-page.jpg");
  image-resolution: inherit;
  background-size: cover;
  border-radius: 2px;
  height: 40vh;
}
.overlay {
  background-color: #000000;
  opacity: 0.65;
  width: 100%;
  height: 100%;
  transition: background 0.3s,
  border-radius 0.3s, opacity 0.3s;
}
.inv__name{
  color: #FFFFFF;
  font-family: 'Belleza', sans-serif;
  font-size: 34px;
  line-height: 44px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 3px;
  text-transform: uppercase;
}
.inv__name__second{
  color: black;
  font-family: 'Belleza', sans-serif;
  font-size: 34px;
  line-height: 44px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 3px;
  text-transform: uppercase;
}
.inv__sub{
  color: #494949;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  line-height: 28px;
  font-style: normal;
  font-weight: 300;
}
.footer_text{
  font-family: "Lato", sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 24px;
  font-weight: 300;
  text-transform: none;
  font-style: normal;
  color: #727272
}
</style>

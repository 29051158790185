<template>
  <v-app data>
    <AppHeader/>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>
<script>

import AppHeader from "@/components/reusables/Header";
export default {
  name: 'App',
  components: {AppHeader},
  data: () => ({
    //
  }),
};
</script>

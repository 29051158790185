<template>
  <div>
    <div class="tw-flex tw-items-center tw-h-full tw-justify-center inv__name">Contact Us</div>
    <div class="tw-flex tw-items-center tw-h-full tw-justify-center inv__sub">We would love to discuss your next project with you.</div>
    <div class="tw-w-6/12 tw-mx-auto tw-flex tw-justify-between tw-mt-40">
      <div>
        <div class="inv__email">ADDRESS</div>
        <div>1st Floor Flat 186, Stamford Hill, London,<br/> United Kingdom, N16 6QX</div>
      </div>
      <div>
        <div class="inv__email">PHONE NO</div>
        <div>07733491659</div>
      </div>
      <div>
        <div class="inv__email">EMAIL</div>
        <div>gllove105@gmail.com</div>
      </div>
    </div>
    <div class="tw-w-11/12 mx-auto tw-mt-10 tw-mb-8 tw-absolute tw-bottom-0 tw-left-14">
      <v-footer height="55" style="border-radius: 2px">
        <div class="footer_text md:tw-ml-20">All rights reserved © denis silva limited 2024</div>
      </v-footer>

    </div>

  </div>
</template>

<script>
export default {
  name: "ContactPage"
}
</script>

<style scoped>
.inv__name{
  color: Black;
  font-family: 'Belleza', sans-serif;
  font-size: 34px;
  line-height: 44px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 3px;
  text-transform: uppercase;
}
.inv__sub{
  color: #494949;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  line-height: 28px;
  font-style: normal;
  font-weight: 300;
}
.inv__email{
  color: Black;
  font-family: 'Belleza', sans-serif;
  font-size: 24px;
  line-height: 44px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 3px;
  text-transform: uppercase;
}


</style>

<template>
  <div app>
    <v-app-bar
        color="white"
        elevate-on-scroll
        app
        clipped-left
        height="95px"
    >
      <div class="tw-block md:tw-hidden">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      </div>
      <div class="tw-flex md:tw-block tw-items-center tw-justify-center tw-w-full">
        <v-avatar tile height="80" width="220" color="white" rounded>
          <v-img :src="marco_image"/>
        </v-avatar>
      </div>
      <div class="tw-hidden md:tw-block tw-items-center tw-justify-center tw-w-full tw--ml-52">
        <div  class="tw-flex tw-gap-9">
          <div v-for="(item) in navItems" :key="item.id" class="nav__div">
            <router-link class="tw-cursor-pointer" tag="div" exact-active-class="active__link" :to="item.route">{{item.name}}</router-link>
          </div>
        </div>
      </div>
      <ul class="tw-flex sm:tw-hidden">
        <li>
          <div
              class="tw-absolute tw-right-0 tw-w-full tw-mt-12 tw-transition"
              v-if="drawer"
          >
            <ul style="background-color: #FAF5F2" class="tw-flex tw-flex-col tw-w-full tw-rounded tw-shadow-md tw-overflow-hidden">
              <div v-for="(item) in navItems" :key="item.id" class="nav__div tw-border-t tw-px-4 tw-py-4">
                <router-link @click="drawer = false" tag="div" exact-active-class="active__link" :to="item.route">{{item.name}}</router-link>
              </div>
            </ul>
          </div>
        </li>
      </ul>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  data(){
    return {
      drawer: false,
      group: null,
      showMenu: false,
      navItems:[
        {
          id: 1,
          name: 'Home',
          route: '/'
        },
        {
          id: 2,
          name: 'About',
          route: '/about'
        },
        {
          id: 3,
          name: 'Contact',
          route: '/contact'
        },
      ],
      logoImage: require('../../assets/images/second_logo.png'),
      marco_image: require('../../assets/images/logo.png')

    }
  },
  created() {
    this.drawer = false
  },
  watch: {
    '$route.path': {
      handler: async function (oldVal, newVal) {
        if(newVal){
          this.drawer = false
        }
      },
      immediate: true
    },
  },

}
</script>

<style scoped lang="scss">
.active__link{
  color: #303030;
}
.not__active__link{
  color: white;
}
.nav__div{
  color: #9d9d9d;
  font-family: Belleza, sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}
a:active {
  color: red;
}
li.active {
  border: solid 12px #f51d19;
}
</style>

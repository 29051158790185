<template>
  <div>
    <HomePage/>
  </div>
</template>

<script>
import HomePage from "@/components/home/HomePage";
export default {
  name: "HomePageView",
  components: {HomePage}
}
</script>

<style scoped>

</style>

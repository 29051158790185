<template>
  <div>
    <div class="home_background lg:tw-w-11/12 tw-mx-auto"></div>
    <div class="lg:tw-w-8/12 tw-w-11/12 tw-mx-auto md:tw-flex lg:tw-gap-10 tw-gap-5 tw-mt-4">
      <div>
        <v-img style="border-radius: 2px" width="500" height="500" src="../../assets/images/home-textile 2.jpg"></v-img>
      </div>

      <div>
        <div class="inv__name tw-mt-3 md:tw-mt-0">Denis Silva Limited</div>
        <span class="first__el tw-mr-1">D</span><span class="inv__sub">enis Silva Textiles is a name renowned for its exceptional craftsmanship and
        innovative approach to fabric design. Our mission is to bring life to every project by offering premium textiles that elevate
        the aesthetic and functional qualities of any space. Whether you're outfitting a luxurious home, a dynamic office, or a
        sophisticated hospitality setting, Denis Silva Textiles ensures that each fabric is a blend of style, durability, and comfort
        . We specialize in the creation of bespoke fabrics, offering an extensive range of textures, patterns, and colors that cater
        to the unique needs of our clients. Our commitment to quality is matched only by our dedication to sustainability,
        using eco-friendly practices to create textiles that are both beautiful and kind to the environment. At Denis Silva Textiles,
        we don't just create fabrics; we craft the foundation for your design vision, ensuring every detail aligns with your aesthetic
        aspirations. Discover the Denis Silva Textiles difference today, where artistry meets functionality, and every piece tells a
        story of quality and craftsmanship.</span>
      </div>
    </div>
    <div class="tw-w-7/12 tw-mx-auto">
      <v-btn
          v-scroll="onScroll"
          v-show="fab"
          class="md:tw-mr-16 tw-mr-4 tw-mb-5"
          fab
          dark
          fixed
          bottom
          height="45"
          width="45"
          right
          color="#095AD3"
          @click="toTop"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </div>

    <div class="lg:tw-w-8/12 tw-w-11/12 tw-mx-auto tw-mt-4">
      <div class="inv__name tw-mb-4">Our Gallery Speak for Itself</div>
      <div class="md:tw-flex tw-justify-between">
        <div class="md:tw-w-7/12 inv__sub">
          Our team of expert designers works closely with our clients to understand their vision
          and bring it to life. We use only the highest quality materials and work with skilled
          craftsmen to ensure that every project is completed to the highest standards.
        </div>
        <div class="tw-flex tw-justify-end mt-4">
          <v-btn
              color="#095AD3"
              class="white--text"
              style="text-transform: none"
              elevation="0"
              large
          >
            View Our Gallery
          </v-btn>
        </div>
      </div>
      <div class="tw-mt-20 md:tw-flex tw-gap-5">
        <div v-for="(item) in view" :key="item.id">
<!--          <div :style="{backgroundImage: 'url(' + item.image + ')'}" class="img__div"></div>-->
          <v-img class="tw-mb-4 md:tw-mb-0" :src="item.image" height="360" width="360" style="border-radius: 8px"/>
        </div>

      </div>
    </div>
    <div class="tw-w-11/12 mx-auto tw-mt-10 tw-mb-8">
      <v-footer height="55" style="border-radius: 2px">
        <div class="footer_text md:tw-ml-20">All rights reserved © denis silva limited 2024</div>
      </v-footer>

    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  data(){
    return {
      image: '../../assets/images/gallery1.jpeg',
      marco_home: require('../../assets/images/marco_homepage.jpeg'),
      fab: false,
      view:[
        {
          id:1,
          image: require('../../assets/images/textile-footer 1.jpg')
        },
        {
          id:2,
          image: require('../../assets/images/textile footer 2.jpg')
        },
        {
          id:3,
          image: require('../../assets/images/textile footer 3.jpg')
        }
      ]
    }
  },
  methods:{
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  }
}
</script>

<style scoped>
.home_background{
  background-image: url("../../assets/images/textile-homepage1.jpg");
  image-resolution: inherit;
  background-size: cover;
  border-radius: 2px;
  height: 80vh;
}
.img__div{
  height: 360px;
  width: 360px;
  background-size: contain;
  border-radius: 8px;
}
.first__el{
  float: left;
  text-align: center;
  line-height: 1;
  font-family: 'Lato', sans-serif;
  font-size: 50px;
}
.inv__name{
  color: #000000;
  font-family: 'Belleza', sans-serif;
  font-size: 34px;
  line-height: 44px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 3px;
  text-transform: uppercase;
}
.inv__sub{
  color: #494949;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  line-height: 28px;
  font-style: normal;
  font-weight: 300;
}
.footer_text{
  font-family: "Lato", sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 24px;
  font-weight: 300;
  text-transform: none;
  font-style: normal;
  color: #727272
}
</style>
